import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import '../styles/main.css'
import bookButton from '../images/book-button.png'
import SEO from '../components/seo'
import samCuttingHair from '../images/sam-cutting-hair.jpg'
import samCuttingHair2 from '../images/sam-cutting-2.jpg'

export default function New() {
    const data = useStaticQuery(
        graphql`
          query {
            main: file(relativePath: { eq: "pole.jpg" }) {
              childImageSharp {
                fluid(maxWidth: 3000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `
      )
    
      // Set ImageData.
      const imageData = data.main.childImageSharp.fluid

    return (
    // <BackgroundImage
    //     Tag="section"
    //     className="homepage-main-img"
    //     fluid={imageData}
    //     >
    <>
    <SEO title="Home" />
    <div className="homepage-main-img">
        <div className="homepage-content">
            <a className="book-link" href="https://bookings.gettimely.com/jsamsbarbershop/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F62250%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue">
                <img className="book-btn" src={bookButton} />
            </a>
            <div className="text-content">
                <h1 style={{margin: '0px', display:'inline-block', verticalAlign: 'top', textAlign:'left'}}>J. Sam’s Barbershop</h1> <h2 style={{margin: '0px', display:'inline-block', verticalAlign: 'top'}}> sets itself apart from the modern cookie-cutter “salons” by embracing the classic barbershop theme. The look and feel of the shop is designed down to the finest detail to revive the best qualities of the great tradition that is the classic barbershop.</h2>
                <h2>Barbershops were once central fixtures in the small, tight-knit communities that dotted the country, places where men could gather and have the type of camaraderie that’s increasingly fading from modern life. Our barbershop has restored this classic vibe for Fayette County, and we’re always working to make the experience even better for our customers.</h2>
            </div>
            {/* <p>To respect your time we are by appointment only.</p>
            <p>Located at the corner of Bennett St. and Stonewall Ave. in downtown Fayetteville.</p>
            <p>E-mail us at jsamsbarbershop@gmail.com</p> */}
            
            <img className="pic" src={samCuttingHair} />

            <div className="text-content">
                <h2 id="services-header">SERVICES</h2>

                <div className="service-description">
                    <h3 className="cat-head">HAIRCUT</h3>
                    <p>Deluxe haircut with hot lather, straight razor neck shave.</p>
                </div>

                <div className="service-description">
                    <h3 className="cat-head">BEARD TRIM</h3>
                    <p>Expertly crafted beard sculpting.</p>
                </div>

                <div className="service-description">
                    <h3 className="cat-head">COMBO HAIRCUT & BEARD TRIM</h3>
                    <p>Enjoy our deluxe haircut with neck shave, and our expertly crafted beard sculpting.</p>
                </div>

                <div className="service-description">
                    <h3 className="cat-head">HEADSHAVE</h3>
                    <p>Enjoy our expertly executed Hot Lather head shave process.</p>
                </div>

                <div className="service-description">
                    <h3 className="cat-head">STUDENT OR CHILDREN'S HAIRCUT</h3>
                </div>
                
                <div className="service-description">
                    <h3 className="cat-head">COMBO HEADSHAVE & BEARD TRIM</h3>
                    <p>Enjoy the "full service" Headshave option as well as a expertly crafted beard trim.</p>
                </div>

                <a className="book-link" href="https://bookings.gettimely.com/jsamsbarbershop/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F62250%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue">
                    <img className="book-btn" src={bookButton} />
                </a>
            </div>

            <img className="pic" src={samCuttingHair2} />

            <div className="text-content">
                <h2 className="cat-head">To respect your time we are by appointment only.</h2>

                <h2 className="location-text">Located at 155 Stonewall Ave West at  the corner of Bennett St. & Stonewall Ave. in downtown Fayetteville.</h2>
                
                <h4 style={{textAlign:'center'}}>E-mail us at jsamsbarbershop@gmail.com</h4>
            </div>

            <iframe style={{alignSelf:'center', margin: '20px 0'}} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3329.050145526062!2d-84.45895748485076!3d33.448000056776806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88f4ee3520905851%3A0x9f47f4db1b302ae1!2s155%20Stonewall%20Ave%20W%2C%20Fayetteville%2C%20GA%2030214!5e0!3m2!1sen!2sus!4v1639606457357!5m2!1sen!2sus" className="map" allowfullscreen="" loading="lazy"></iframe>
            <a className="book-link" href="https://bookings.gettimely.com/jsamsbarbershop/book?uri=https%3A%2F%2Fbook.gettimely.com%2FBooking%2FLocation%2F62250%3Fmobile%3DTrue%26params%3D%25253fclient-login%25253dtrue">
                <img className="book-btn" src={bookButton} />
            </a>
        </div>
    </div>
    </>
    )
}
